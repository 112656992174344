@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&family=Bebas+Neue&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: 'Raleway', sans-serif;
    cursor: none;
  }

}

.cursor-small {
  position: fixed; /* Change to fixed so it doesn't scroll with the page */
  width: 80px;
  height: 80px;
  border: 1px solid whitesmoke;
  background: white;
  transform: translate(-50%, -50%) scale(0.3); /* Initial scale */
  border-radius: 50%;
  top: var(--y, 0);
  left: var(--x, 0);
  opacity: 1;
  z-index: 120;
  pointer-events: none;
  mix-blend-mode: difference;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.cursor-large {
  transform: translate(-50%, -50%) scale(1); /* Enlarged cursor on hover */
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'coign';
  src: url('./assets/fonts/Coign\ Pro\ 47\ Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'mulish';
  src: url('./assets/fonts/Mulish-Light.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.container {
  width: 100px;
  height: 100px;
  /* display: flex; */
  place-content: center;
  overflow: hidden;
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 30px;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.home-bg-text {
  font-family:"coign","Antonio",sans-serif;
  font-weight:normal;
  font-size: 28rem;
  opacity: 0;
  /* z-index: -1; */
  transform: scale(1.4,1.1); 
  /* display: inline-block; Needed for transform to work properly */
  line-height: normal; 
  color:transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: url("/public/home-bg-9.avif");
  background-size: 110%;  
  animation: bg-animation 20s cubic-bezier(0.3,0,0.7,0.8) infinite, text-animation 1.7s ease-in-out forwards 3s;
  user-select: none;
}

@media (max-width: 768px) {
  .home-bg-text {
    font-size: 7rem;
  }
}

@keyframes bg-animation {
  0% {background-position: 0% 0%;}
  50% {background-position: 100% 100%;}
  100% {background-position: 0% 0%;}
}

@keyframes text-animation {
  0% {
    transform: translateY(70px) scale(1.4,1.1);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1.4,1.1) ; 
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .-bg-thomeext {
    font-size: 8rem;
  }
}

.grainy {
  height: 100vh;
  background-image: url("/public/noise.png");
}

.grainy:after {
  animation: grain 4s steps(10) infinite;
  background-image: url("/public/noise.png");
  content: "";
  height: 300%;
  left: -50%;
  /* mix-blend-mode: color-dodge; */
  opacity: 0.3;
  position: fixed;
  top: -100%;
  width: 300%;
  pointer-events: none;
  z-index: 100;

}

@keyframes grain {
  0%, 100% { transform:translate(0, 0) }
  10% { transform:translate(-5%, -10%) }
  20% { transform:translate(-15%, 5%) }
  30% { transform:translate(7%, -25%) }
  40% { transform:translate(-5%, 25%) }
  50% { transform:translate(-15%, 10%) }
  60% { transform:translate(15%, 0%) }
  70% { transform:translate(0%, 15%) }
  80% { transform:translate(3%, 35%) }
  90% { transform:translate(-10%, 10%) }
}


/* experience component css */


 
 .circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
 }
 
 .circle-black {
  background-color: white;
 }
 
 .vline {
  border-left: 3px solid;

 }
 .vline2 {
  border-left: 3px solid;
 }


/* navbar css */ 



.hideNav {
  transform: translateY(-100%);
}

.showNav {
  transform: translateY(0%);
}


